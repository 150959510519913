<template>
  <div>
    <el-card>
      <common-user-center-title :title="'我的下级'"></common-user-center-title>
      <el-table :data="mySubordinateList" border stripe>
        <el-table-column type="index"/>
        <el-table-column label="昵称" prop="name"/>
        <el-table-column label="头像" width="70" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
            >
              <el-image
                slot="reference"
                style="width: 40px; height: 40px"
                :src="scope.row.image"
                fit="cover"
              />
              <el-image
                style="width: 220px; height: 220px"
                :src="scope.row.image"
                fit="cover"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="等级" width="100" prop="levelName"/>
        <el-table-column label="下级数量" width="80" prop="lowestLevelNums"/>
        <el-table-column label="申请时间" prop="created_at"/>
        <el-table-column label="操作" align="center" width="80">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看下架" placement="top">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-user"
                @click="lookSubordinatePerson(scope.row.id)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination class="mt-2"
                     :current-page="mySubordinateQueryInfo.page"
                     :page-sizes="[10, 20, 50, 100]"
                     :page-size="mySubordinateQueryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="mySubordinateTotal"
                     @size-change="mySubordinateSizeChange"
                     @current-change="mySubordinateCurrentChange"/>
      <!-- 我的下级的下级弹窗 -->
      <el-dialog
        title="下级列表"
        :visible.sync="subordinatePersonDialogVisible"
        width="50%">
        <el-table :data="subordinatePersonList" border stripe>
          <el-table-column type="index"/>
          <el-table-column label="昵称" prop="name"/>
          <el-table-column label="头像" width="70" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="right"
                trigger="hover"
              >
                <el-image
                  slot="reference"
                  style="width: 40px; height: 40px"
                  :src="scope.row.image"
                  fit="cover"
                />
                <el-image
                  style="width: 220px; height: 220px"
                  :src="scope.row.image"
                  fit="cover"
                />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="等级" width="100" prop="levelName"/>
          <el-table-column label="下级数量" width="80" prop="lowestLevelNums"/>
          <el-table-column label="申请时间" width="160" prop="created_at"/>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination class="mt-2"
                       :current-page="subordinatePersonQueryInfo.page"
                       :page-sizes="[5, 10, 20, 50]"
                       :page-size="subordinatePersonQueryInfo.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="subordinatePersonTotal"
                       @size-change="subordinatePersonSizeChange"
                       @current-change="subordinatePersonCurrentChange"/>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="subordinatePersonDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="subordinatePersonDialogVisible = false">确 定</el-button>
          </span>
        </template>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { mySubordinateListUrl } from '@/api/index'

export default {
  name: 'MySubordinate',
  components: {
    commonUserCenterTitle
  },
  data () {
    return {
      mySubordinateList: [],
      mySubordinateTotal: 0,
      mySubordinateQueryInfo: {
        distributeUserId: 0,
        pageSize: 10,
        page: 1,
        field: '',
        desc: '',
      },
      subordinatePersonDialogVisible: false,
      subordinatePersonList: [],
      subordinatePersonTotal: 0,
      subordinatePersonQueryInfo: {
        distributeUserId: '',
        pageSize: 5,
        page: 1,
        field: '',
        desc: '',
      },
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.geMySubordinateList()
    },
    // 获取我的下级列表
    async geMySubordinateList () {
      const { data: res } = await this.$http.get(mySubordinateListUrl, { params: this.mySubordinateQueryInfo })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.mySubordinateList = res.data.list
      this.mySubordinateTotal = res.data.total
    },
    // 分页页码显示条数
    mySubordinateSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.mySubordinateQueryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.geMySubordinateList()
    },
    // 页面切换事件
    mySubordinateCurrentChange (newPage) {
      this.mySubordinateQueryInfo.page = newPage
      // 重新获取数据并显示
      this.geMySubordinateList()
    },
    // 查看下级
    lookSubordinatePerson (distributeUserId) {
      this.getSubordinatePersonList(distributeUserId)
      this.subordinatePersonList = []
      this.subordinatePersonDialogVisible = true
    },
    // 获取下级的下级列表
    async getSubordinatePersonList (distributeUserId) {
      if (distributeUserId) {
        this.subordinatePersonQueryInfo.distributeUserId = distributeUserId
      }
      const { data: res } = await this.$http.get(mySubordinateListUrl, { params: this.subordinatePersonQueryInfo })
      console.log(res)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.subordinatePersonList = res.data.list
      this.subordinatePersonTotal = res.data.total
    },
    // 分页页码显示条数
    subordinatePersonSizeChange (newSzie) {
      // 把每页显示多少数据重新赋值
      this.mySubordinateQueryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getSubordinatePersonList()
    },
    // 页面切换事件
    subordinatePersonCurrentChange (newPage) {
      this.mySubordinateQueryInfo.page = newPage
      // 重新获取数据并显示
      this.getSubordinatePersonList()
    },
  }
}
</script>

<style lang="less" scoped>
  .title-option {
    position: absolute;
    top: 14px;
    left: 120px;

    .el-tabs__nav-wrap::after {
      background-color: transparent;
    }
  }
</style>
